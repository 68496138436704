.editor-image {
    width: 100%;
    min-width: 300px;
}

label {
    display : block;
}

.tool-tip-icon {
    width: 25px;
    padding: 0px;
    margin: 0px;
}


#editor-selector {
    list-style-type: none;
    display : flex;
    padding: 0px;
    margin: 0px;
    display:flex;
    box-sizing: border-box;
    gap: 2px;
}

.edit-parent {
    background-color: white;
    box-sizing: border-box;
    box-shadow: 4px 4px rgb(232, 185, 43);
    padding : 15px;
}

.big-container {
    margin: 10px;
    width: 400px;
    min-width: 200px;
    box-sizing: border-box;
    flex: 1 0 auto;
}

#editor-selector li {
    width: 100%;
    padding: 10px;
    background-color: lightgrey;
    border-radius: 5px 5px 0% 0%;
    text-align: center;
    font-size: 20px;
    font-family: Made-Infinity;
    box-sizing: border-box;
}


.preview-box {
    padding : 10px;
    background-color: white;
    display: inline-block;
    margin: 5px;
    border-radius : 10px;
    flex: 1 0 auto;
    align-self: start;
}

.tool-tip-icon {
    padding: 0px 10px;
}

.preview-title-box {
    display: flex;
    flex-direction: row;
}


.biggest-edit-cont {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

label {
    font-family: Made-Infinity;
}

.side-to-side button {
    padding: 5px 30px;
    align-self: center;
    box-sizing: border-box;
}

.side-to-side {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
}