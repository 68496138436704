#image-upload {
    display: inline-flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    max-width: 600px;
    padding: 50px 0px;
    width: 90%;
    background-color: #ffffff;
    border-radius: 10px;
    height: 25vh;
}

#image-upload p {
    padding: 0px;
    margin: 0px;
    font-size: 20px
}

#upload-icon {
    width: 35px;
}

#image-select {
    display: inline-flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    min-width: 160px;
    color: rgb(0, 0, 0);
    text-decoration: none;
    padding: 10px 20px;
    border-radius: 5px;
}

#image-select:hover {
    color: rgb(0, 0, 0);
    text-decoration: none;
    background-color: lightgrey;
}


#drop-zone {
    position: relative;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    border-width: 4px;
    border-color: #efefef;
    border-style: dashed;
    width: 50%;
    height: 60px;
    text-align: center;
}


#image-upload * {
    pointer-events : none;
}