#initial-home-view {
    height: 90vh;
    width: 100vw;
    overflow: hidden;
    position: relative;
}

video {
    position: absolute;
    top: 50%;  
    left: 50%; 
    min-width: 100vw;
    min-height: 100vh;
    transform: translate(-50%, -50%); 
    z-index: 1;
}


.full-size {
    position: absolute;
    top: 0px;  
    left: 0px; 
    width: 100%;
    height: 100%;
    z-index: 3;
}

.transparent-div {
    position: absolute;
    top: 0px;  
    left: 0px; 
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, .6);
    z-index: 2;
}


@media screen and (max-width: 490px) {
    /*focuses the background video on the middle right of the 
    picture on mobile
    */
    video {
        position: absolute;
        top: 50%;  
        left: 55%; 
        transform: translate(-55%, -50%); 
        height: 90vh;
        z-index: 1;
    }
}


#homepage-examples {
    max-width: 100vw;
}


.image-div {
    padding: 0px;
    margin: 5px;
    box-sizing: border-box;
    border-radius: 10px;
}

