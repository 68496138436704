#editor-sliders-parent {
    margin: 5px 0px;
}


#effects-reset-button {
    padding: 5px 30px;
    align-self: center;
    box-sizing: border-box;
}

#effect-content {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
}